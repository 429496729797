/**
 * Contents class used to handle contents events and features
 */
export default class Contents {
  constructor(options = {}) {
    this.#init();
  }

  #init() {
    const seeMoreButton = document.querySelector('.inbenta-km__contents__button--seemore');
    if (seeMoreButton) {
      seeMoreButton.addEventListener('click', () => {
        this.displayMoreContents(seeMoreButton);
      });
    }
  }

  /**
   *
   * @param {HTMLElement|Element} faqsContainer HTML container with all FAQs
   * @param {HTMLElement|Element} seeMoreButton Button used to see more FAQs
   */
  displayMoreContents(seeMoreButton) {
    seeMoreButton.style.display = 'none';
    const faqs = document.querySelectorAll('.inbenta-km__faq');
    faqs.forEach((content) => {
      content.style.display = 'block';
    });
  }
}
