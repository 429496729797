import { click } from './tracking';

/**
 * Bind the click event on the given element
 *
 * @param {HTMLElement|Element} element Element to bind click event to
 */
export default function bindClickEvent(element) {
  const trackingKey = element.getAttribute('data-tracking');
  const trackingData = JSON.parse(element.getAttribute('data-trackdata'));
  let href = element.getAttribute(element.nodeName === 'A' ? 'href' : 'data-href');

  if (element.classList.contains('inbenta-km__autocompleter__link')) {
    trackingData.autocomplete = true;
    trackingData.query = document.getElementById('inbenta-km__search__form__input').value;
  }

  click(trackingKey, trackingData).then((response) => {
    // redirect after the click
    if (element.nodeName === 'A') {
      if (response.length) {
        href = response;
      }
      window.location.href = href;
    }
  });
}
