/**
 * @author BOUCHER Clément <cboucher@inbenta.com>
 * @copyright Inbenta Technologies Inc.
 */

import { post } from './request';

/**
 * Function to send tracking event
 *
 * @param {string} type Tracking type (rate, click, ...)
 * @param {object} data Data object
 * @returns {Promise<any>|void} a clausure
 */
export default function sendTracking(type, data) {
  // send tracking data
  return post('/tracking', {
    type,
    data
  }).catch((reason) => console.log('[INBENTA] An unexpected error occurred during tracking...', reason.responseText));
};

/**
 * Function to send tracking event on rating
 *
 * @param {string} trackingCode Tracking code to use
 * @param {string} dataValue Value of the rating
 * @param {?string} comment Comment value to send with
 * @returns {Promise<any>|void} a promise
 */
export function rate(trackingCode, dataValue, comment = null) {
  // define tracking data
  const data = {
    code: trackingCode,
    value: dataValue
  };
  // add comment if defined
  if (typeof comment === 'string') {
    data.comment = comment;
  }
  return sendTracking('rate', data);
}

/**
 * Function to send tracking event on click
 *
 * @param {string} trackingKey Tracking identifier to identify tracking type
 * @param {{}} data Additional data to send
 * @returns {Promise<any>|void} a promise
 */
export function click(trackingKey, data = {}) {
  // send click tracking
  return sendTracking('content', Object.assign({}, data, { trackingKey }));
}
